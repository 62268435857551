<template>
  <div class="h-100">
    <v-app-bar
      app
      class="white"
      flat
    >
      <v-app-bar-nav-icon @click.stop="navDrawer = !navDrawer" />
      <v-toolbar-title class="font-weight-bold primary--text pl-0">
        {{headerTitle}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <NotificationList
        @fetchValueMenu="fetchValueMenu"
      />
      <v-divider
        vertical
        inset
      ></v-divider>
      <v-menu
        bottom
        min-width="250px"
        offset-y
        rounded="sm"
      >
        <template v-slot:activator="{ on }">
          <v-list
            dense
            flat
            max-width="320"
            class="transparent"
          >
            <v-list-item
              v-on="on"
              :ripple="false"
            >
              <v-list-item-content v-if="!isMobile">
                <v-list-item-title v-text="user.name"></v-list-item-title>
                <v-list-item-subtitle v-text="user.role"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon class="grey--text">
                  mdi-chevron-down
                </v-icon>
              </v-list-item-action>
              <v-list-item-avatar>
                <v-img
                  v-if="photoProfileUrl"
                  :src="photoProfileUrl"
                ></v-img>
                <img v-else src="@/assets/images/userProfile.png">
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </template>
        <v-list dense>
          <div v-if="isMobile">
            <v-list-item>
              <v-list-item-avatar class="mr-5">
                <v-img
                  v-if="photoProfileUrl"
                  :src="photoProfileUrl"
                ></v-img>
                <img v-else src="@/assets/images/userProfile.png">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{user.name}}</v-list-item-title>
                <v-list-item-subtitle>{{user.role}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
          <v-list-item
            :disabled="path === '/main/account/info'"
            class="px-0"
            @click="routeNavigate('/main/account/info')"
          >
            <v-list-item-avatar
              max-width="25"
              max-height="25"
            >
              <v-icon>
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Akun</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="path === '/main/about'"
            @click="routeNavigate('/main/about')"
            class="px-0"
          >
            <v-list-item-avatar
              max-width="25"
              max-height="25"
            >
              <v-icon>
                mdi-warehouse
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Tentang Kami</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="myUserInfo && myUserInfo.roleName === 'TRANSPORTER_GUEST2'"
            :disabled="path === '/main/dokumen-legal/list'"
            @click="routeNavigate('/main/dokumen-legal/list')"
            class="px-0"
          >
            <v-list-item-avatar
              max-width="25"
              max-height="25"
            >
              <v-icon>
                mdi-file-document-multiple
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Dokumen Legal</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="path === '/main/contact-us'"
            class="px-0"
            @click="routeNavigate('/main/contact-us')"
          >
            <v-list-item-avatar
              max-width="25"
              max-height="25"
            >
              <v-icon>
                mdi-phone
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Hubungi Kami</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="path === '/main/privacy-policy'"
            @click="routeNavigate('/main/privacy-policy')"
            class="px-0"
          >
            <v-list-item-avatar
              max-width="25"
              max-height="25"
            >
              <v-icon>
                mdi-file-document-check-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Kebijakan Privasi</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            class="px-0"
            @click="logout"
          >
            <v-list-item-avatar
              max-width="25"
              max-height="25"
            >
              <v-icon>
                mdi-logout
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Keluar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="navDrawer"
      app
      dark
      class="side-nav"
      width="200"
    >
      <div class="logo-img my-2">
        <v-img
          contain
          width="180"
          height="55"
          src="@/assets/images/fli_logo_white.png"
        ></v-img>
      </div>
      <v-divider class="mx-5 mb-8 grey"></v-divider>
      <v-list nav>
        <template v-for="(item, index) in sideNavs">
          <v-list-group
            v-if="item.child.length > 0"
            :key="item.text"
            :value="item.collapsed($route)"
            class="transparent mb-2"
            :class="item.collapsed($route) ?
              sideNavs.length === index + 1
                ? 'sidenav-group-active mb-12'
                :'sidenav-group-active' :
              sideNavs.length === index + 1 ? 'sidenav-group mb-12' : 'sidenav-group'"
            active-class="primary--text"
            :ripple="false"
          >
            <template v-slot:activator>
              <v-list-item
                :input-value="true"
                class="pl-1 pr-0 mb-0"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>{{ item.model ? item.icon : (item['icon-alt'] || item.icon) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    <v-icon
                      v-if="
                        (
                          item.menuName.toLowerCase() === 'kendala'
                          && accidentExists
                        ) ||
                          (
                            item.menuName.toLowerCase() === 'persetujuan'
                            && inboxUnread > 0
                          )
                      "
                      color="red"
                      small
                    >
                      mdi-alert-octagon
                    </v-icon>
                    {{ item.menuName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.child"
              :key="i"
              :to="child.url"
              dense
              class="sidenav-child my-0 rounded-0"
              :class="i === (item.child.length -1) ? 'rounded-sm' : '' "
              light
              active-class="sidenav-child-active"
            >
              <v-list-item-action class="mr-2"></v-list-item-action>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  <v-icon
                    v-if="child.menuName.toLowerCase() === 'masalah pengiriman' && accidentExists"
                    color="red"
                    small
                  >
                    mdi-alert-octagon
                  </v-icon>
                  <span>{{ child.menuName }}</span>
                  <span v-if="child.menuName.toLowerCase() === 'kotak masuk' && inboxUnread > 0">
                    <v-badge
                      color="red"
                      :content="inboxUnread"
                      class="mt-4 ml-2"
                    >
                    </v-badge>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.name"
            :to="item.url"
            active-class="primary--text"
            :ripple="false"
            :class="sideNavs.length === index + 1 ? 'mb-12' : ''"
            class="side-nav_item px-3 rounded-sm"
          >
            <v-list-item-action class="mr-3">
              <v-icon>{{ item.icon == 'icon' ? 'mdi-information': item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <v-icon
                  v-if="item.menuName.toLowerCase() === 'pesanan' && waitingOrder"
                  color="red"
                  small
                >
                  mdi-alert-octagon
                </v-icon>
                {{ item.menuName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main class="main-container h-100 pb-10">
      <router-view></router-view>
    </v-main>
    <v-footer color="#F1F5F8" class="d-flex justify-center">
      <p>Copyright &copy; 2019 - {{yearNow}} <a target="_blank" href="http://">fastlog.id</a> All Rights Reserved.</p>
    </v-footer>
  </div>
</template>

<script>
import { requestPermission } from '@/helper/fcm';
import NotificationList from '../components/NotificationList';

// check if route in children matched to current route
const isCollapsed = (route, children) => children.some((i) => route.matched.some((r) => new RegExp(`^${i.url}`).test(r.path)));

export default {
  name: 'MainContainer',
  components: {
    NotificationList,
  },
  mounted() {
    const dataUser = this.$store.state.user.myUserInfo;
    this.user.name = dataUser.username || '';
    this.user.role = dataUser.rolesName || '';
    let menu = [];
    try {
      menu = JSON.parse(this.$store.getters['auth/menu']);
    } catch (e) {
      this.logout();
    }
    if (menu) {
      this.navs = JSON.parse(this.$store.getters['auth/menu']);
      this.requestPermissionNotification();
      this.checkMenuForSetIcon();
      this.setUserAccess();
      this.fetchValueMenu();
    }
  },
  data() {
    return {
      yearNow: new Date().getFullYear(),
      navDrawer: null,
      navs: [],
      user: {
        name: '',
        role: '',
      },
      isSupportedNotification: false,
      isCreated: false,
      customizeMenu: {
        'Kotak Masuk': false,
        Pesanan: false,
        'Masalah Pengiriman': false,
      },
      loaders: {
        fetchingInboxUnread: false,
        fetchingAccidentExists: false,
        fetchingWaitingOrder: false,
      },
    };
  },
  watch: {
    path() {
      this.setUserAccess();
    },
    userAccess() {
      if (!this.isSupportedNotification && this.isCreated) this.fetchValueMenu();
    },
  },
  computed: {
    headerTitle() {
      return this.$route.query.headerTitle || this.$route.meta.headerTitle || '';
    },
    path() {
      return this.$route.path;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    sideNavs() {
      return this.navs.map((i) => ({
        ...i,
        collapsed(route) {
          return isCollapsed(route, this.child);
        },
      }));
    },
    photoProfileUrl() {
      if (!this.myUserInfo) return null;
      if (this.myUserInfo.imageUrl === 'https://placehold.it/200') {
        return null;
      }
      return this.myUserInfo.imageUrl;
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    inboxUnread() {
      return this.$store.getters['user/inboxUnread'];
    },
    waitingOrder() {
      return this.$store.getters['user/waitingOrder'];
    },
    accidentExists() {
      return this.$store.getters['user/accidentExists'];
    },
    findMenuOrder() {
      return this.navs.find((menu) => menu.menuName.toLowerCase() === 'pesanan');
    },
    userAccess() {
      return this.$store.getters['user/userAccess'];
    },
  },
  methods: {
    requestPermission,
    routeNavigate(path) {
      this.$router.push(path);
    },
    async requestPermissionNotification() {
      const isSupported = await this.requestPermission();
      this.isSupportedNotification = isSupported;
      this.isCreated = true;
    },
    checkMenuForSetIcon() {
      const targetMenu = ['Kotak Masuk', 'Pesanan', 'Masalah Pengiriman'];
      this.navs.forEach((nav) => {
        if (targetMenu.includes(nav.menuName)) this.customizeMenu[nav.menuName] = true;
        nav.child.forEach((navChild) => {
          if (targetMenu.includes(navChild.menuName)) this.customizeMenu[navChild.menuName] = true;
        });
      });
    },
    setUserAccess() {
      const { path } = this.$route;
      const replacePath = (_path, toIndex) => `/${_path.split('/').splice(1, toIndex - 1).toString().replaceAll(',', '/')}`;
      let selectedMenu;
      this.navs.forEach((nav) => {
        if (new RegExp(`^${nav.url}$`).test(replacePath(path, nav.url.split('/').length))) {
          selectedMenu = nav;
        }
        if (nav.child) {
          nav.child.forEach((navChild) => {
            if (navChild.url && new RegExp(`^${navChild.url}$`).test(replacePath(path, navChild.url.split('/').length))) {
              selectedMenu = navChild;
            }
          });
        }
      });
      this.$store.dispatch('user/setUserAccess', selectedMenu);
    },
    async fetchValueMenu() {
      if (this.customizeMenu.Pesanan && !this.loaders.fetchingWaitingOrder) {
        try {
          this.loaders.fetchingWaitingOrder = true;
          const res = await this.$_services.order.orderExists();
          this.$store.dispatch('user/setWaitingOrder', res.data);
        } finally {
          this.loaders.fetchingWaitingOrder = false;
        }
      }
      if (this.customizeMenu['Kotak Masuk'] && !this.loaders.fetchingInboxUnread) {
        try {
          this.loaders.fetchingInboxUnread = true;
          const res = await this.$_services.approval.getInboxUnread();
          this.$store.dispatch('user/setInboxUnread', res.data);
        } finally {
          this.loaders.fetchingInboxUnread = false;
        }
      }
      if (this.customizeMenu['Masalah Pengiriman'] && !this.loaders.fetchingAccidentExists) {
        try {
          this.loaders.fetchingAccidentExists = true;
          const res = await this.$_services.shippingProblem.accidentExists();
          this.$store.dispatch('user/setAccidentExists', res.data);
        } finally {
          this.loaders.fetchingAccidentExists = false;
        }
      }
    },
    async logout() {
      try {
        this.$root.$loading.show();
        await this.$_services.auth.logout();
        this.$store.dispatch('auth/logout');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.v-app-bar {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1) !important;
}
.main-container {
  overflow: -moz-scrollbars-none;
  background-color: #f1f5f8;
}
.side-nav {
  ::-webkit-scrollbar { width: 0 !important }
    overflow: -moz-scrollbars-none;
    background: linear-gradient(
      to bottom,
      #02499a 100%,
      #0852a4 40%,
      #1764bb 24%,
      #388ceb 0%
    );
    .logo-img {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-list-item--active::before {
      opacity: 0 !important;
    }
    .side-nav_item {
      &.v-list-item--active {
        background-color: #ffffff;
      }
      &::before {
        border-radius: 5px !important;
      }

      .v-list-item:hover::before {
        opacity: 0;
        background-color: #5997cf;
      }
    }
    .sidenav-group {
      &.v-list-group--active {
        background-color: #ffffff !important;
        border-radius: 5px !important;
      }
      .v-list-item::before {
        border-radius: 5px !important;
      }
    }
    .sidenav-group-active {
      background-color: #ffffff !important;
      border-radius: 5px !important;
      .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: var(--v-primary-base) !important;
      }
      .theme--dark.v-icon {
        color: inherit !important;
      }
    }
    .sidenav-child {
      background-color: #ffffff !important;
      &:hover {
        background-color: #dbe9f6 !important;
      }
      &.v-list-item:hover::before {
        opacity: 0;
      }
    }
    .sidenav-child-active {
      background: #ebedf4 !important;
      color: var(--v-primary-base) !important;
    }
    .v-list-item__content {
      max-width: 131px;
    }
    .v-list-item__icon {
      min-width: 24px !important;
      margin-left: 0 !important;
    }
  }
</style>
